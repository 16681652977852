<template>
	<span class="text-lg text-base-content">Nossos Massagistas</span><br />
	<div class="grid grid-cols-1 md:grid-cols-4 gap-2 place-items-center" id="masseur-gallery">
		<div class="col-span-2">
			<nuxt-img src="/image/index/masseur_2024-11-19.jpg" quality="75" class="block md:hidden rounded-box h-full md:h-[350px]" />
			<label class="hidden md:block" for="masseur">
				<nuxt-img src="/image/index/masseur_2024-11-19.jpg" quality="75" class="rounded-box h-auto md:h-[350px]" />
			</label>
		</div>
		<div class="col-span-2 grid grid-cols-1 gap-5">
			<span v-for="masseur in listMasseur" class="btn btn-outline btn-primary btn-sm btn-block">
				<label v-if="masseur.modal" :for="'masseur-' + masseur.file" v-text="masseur.name"></label>
				<label v-else="!masseur.modal" v-text="masseur.name"></label>
			</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { MasseurList } from '~/composables/useMasseurList'

const listMasseur: MasseurList[] = useMasseurList()
</script>
